<template>
    <a-card>
        <a-page-header
            :title='"广告主账号管理"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="经销商编码">
                        <a-input
                            v-model="form.storeCode"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="公司名">
                        <a-input
                            v-model="form.advertiserCompany"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                    <!-- <a-form-model-item label="品牌（主体）">
                        <a-select
                            v-model="form.advertiserType"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="1">汽车经销商</a-select-option>
                            <a-select-option key="2">汽车品牌</a-select-option>
                        </a-select>
                        <a-select
                            mode="multiple"
                            v-model="externalIdentityIdList"
                            placeholder="请选择"
                            style="width: 100%;"
                            allow-clear
                            show-search
                            :filter-option="false"
                            @search="onChangeDealerBrand"
                        >
                            <a-select-option v-for="item in dealerBrandList" :key="item.id">{{ item.name || item.principal_name }}</a-select-option>
                        </a-select>
                    </a-form-model-item> -->
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告平台">
                        <a-select
                            mode="multiple"
                            v-model="adPlatformIdList"
                            placeholder="请选择"
                            style="width: 100%;"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                </a-col>
                <a-col :span="1"></a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <base-button
                            :title="'重置'"
                            @onClickBtn="handleReset"
                        ></base-button>
                        <base-button
                            :type="'primary'"
                            :title="'查询'"
                            @onClickBtn="handleSearch"
                        ></base-button>
                        <base-button
                            :type="'primary'"
                            :title="'新建广告主'"
                            @onClickBtn="jumpDetailPage('0')"
                        ></base-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record.advertiserId)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'获取授权链接'"
                    @onClickBtn="handleShowLink(record)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'查看平台账套'"
                    @onClickBtn="jumpPlatformPage(record)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'状态修改'"
                    @onClickBtn="jumpStatePage(record.advertiserId)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <!--    配置可见范围-->
        <a-modal
            :visible="linkVisible"
            :footer="null"
            title="广告平台授权链接"
            width="800px"
            @cancel="() => {linkVisible = false}"
        >
            <a-spin :spinning="linkLoading">
                <div class="row txt">
                    <span>广告主：{{ linkInfo.advertiserName || '-' }}</span>
                    <span></span>
                </div>
                <div class="row" v-for="(item, index) in linkDataList" :key="index">
                    <base-button
                        :type="'link'"
                        :title="'复制链接'"
                        :id="`btn_${index}`"
                        :data-clipboard-text="item.authUrl"
                        @onClickBtn="handleCopyUrl(`btn_${index}`)"
                    ></base-button>
                    <base-button
                        :type="'link'"
                        :title="'推送风火轮'"
                        :disabled="true"
                    ></base-button>
                    <div class="row" style="flex: 1">
                        <div class="name">{{item.adPlatform || '-'}}</div>
                        <a-input v-model="item.authUrl"/>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </a-card>
</template>

<script>
import _ from 'lodash'
import Clipboard from 'clipboard'

export default {
    data() {
        this.onChangeDealerBrand = _.debounce(this.onChangeDealerBrand, 1000)

        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
            },
            externalIdentityIdList: [],
            adPlatformIdList: [],
            dealerBrandList: [],
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主编码',
                    dataIndex: 'advertiserCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '经销商编码',
                    dataIndex: 'storeCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌（主体）',
                    // dataIndex: 'principalName',
                    customRender: (text, row) => {
                        if(row.advertiserType == 1) {
                            let txt = ''
                            let arr = row.brandManusList || []
                            arr.map(item => {
                                txt += item.brandName
                            })
                            return txt || '-'
                        } else {
                            return row.principalName || '-'
                        }
                    }
                },
                {
                    align: 'center',
                    title: '状态',
                    // dataIndex: 'status',
                    customRender: (text, row) => {
                        let txt = <div class="red">关闭</div>
                        return row.status == 1 ? '开启' :  txt
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    dataIndex: 'adPlatformName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    key: 'action',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            linkVisible: false,
            linkInfo: {},
            linkLoading: false,
            linkDataList: [],
            platformList: [],
        }
    },
    created() {
        this.getDataList()
        this.getAdPlatformList()
    },
    methods: {
        onChangeDealerBrand(val) {
            if(val) {
                switch(this.form.advertiserType) {
                    case '1':
                        this.$api.base_api.getDealerList({ search: val, state: 1, page_size: 1000 }).then(res => {
                            this.dealerBrandList = res.list || []
                            console.log(this.dealerBrandList)
                        })
                        break;
                    case '2':
                        this.$api.base_api.getPrincipalList({ principal_name: val }).then(res => {
                            this.dealerBrandList = res || []
                        })
                        break;
                }
            }
        },
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.externalIdentityIdList = []
            this.adPlatformIdList = []
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                externalIdentityIds: this.externalIdentityIdList.length > 0 ? this.externalIdentityIdList.toString() : undefined,
                adPlatformIds: this.adPlatformIdList.length > 0 ? this.adPlatformIdList.toString() : undefined,
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.pphtManage.getAccountList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/pphtManage/accountDetail`,
                query: {
                    id
                }
            })
        },
        jumpStatePage(id) {
            this.$router.push({
                path: `/pphtManage/accountState`,
                query: {
                    id
                }
            })
        },
        jumpPlatformPage(record) {
            this.$router.push({
                path: `/pphtManage/platformManage`,
                query: {
                    advertiserName: record.advertiserName
                }
            })
        }, 
        handleShowLink(record) {
            this.linkVisible = true
            this.linkInfo = record
            this.linkLoading = true

            let params = {
                advertiserId: record.advertiserId,
                externalIdentityId: record.externalIdentityId,
                page: 1,
                size: 100
            }
            this.$api.core.pphtManage.getOauthUrlList(params).then(res => {
                this.linkLoading = false
                if(res.code == 200) {
                    this.linkDataList = res.data
                } else {
                    console.error(`获取授权列表失败${res}`)
                }
            })
        },
        handleCopyUrl (_id) {
            const clipboard = new Clipboard(`#${_id}`)
            clipboard.on('success', e => {
                this.$message.success('复制成功')
                clipboard.destroy() // 释放内存
                this.linkVisible = false
                this.linkInfo = {}
            })
            clipboard.on('error', e => {
                // 不支持复制
                this.$message.error('该浏览器不支持自动复制')
                clipboard.destroy() // 释放内存
                this.linkVisible = false
                this.linkInfo = {}
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.txt {
    padding: 0 15px;
}
.name {
    padding: 0 15px;
    white-space: nowrap;
}
</style>